<template>
  <b10-base>
    <b10-toolbar
      :title="pageTitle"
    />
    <b10-page-content>
      <v-container
        fill-height
      >
        <v-layout
          row
          wrap
          align-center
          justify-center
        >
          <v-card
            max-width="400px"
            flat
          >
            <v-card-text
              v-html="$options.filters.linebreaksBr(message)"
            />
            <v-card-actions
              class="justify-center"
            >
              <v-btn
                color="secondary"
                fab
                large
                @click="clickRedirect"
              >
                <v-icon>{{ $vuetify.icons.values.home }}</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-layout>
      </v-container>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { basePageMixin } from '@/mixins/basePageMixin'

export default {
  mixins: [basePageMixin],
  computed: {
    pageTitle () {
      return this.routeQuery.extra?.title || 'Página no encontrada'
    },
    message () {
      return this.routeQuery.extra?.message || 'La página de la aplicación a la que intentas acceder no existe'
    },
  },
  methods: {
    clickRedirect (){
      if (this.routeQuery.extra?.redirectRoute) {
        this.$appRouter.replace(this.routeQuery.extra.redirectRoute)
      } else {
        this.$appRouter.replace({ name: 'index' })
      }
    },
  }
}
</script>
